@font-face {
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 400;
    src: url('https://fonts.gstatic.com/s/opensans/v18/mem8YaGs126MiZpBA-U1Ug.woff2') format('woff2');
}

.landing {
    position: relative;
    height: 100vh; /* Asegura que el componente ocupe toda la pantalla */
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    z-index: 1000;
}

.carousel-container {
    position: relative;
    width: 100%;
    height: 100%;
    overflow: hidden;
}

.carousel-slide {
    position: absolute;
    width: 100%;
    height: 100%;
    opacity: 0;
    transition: opacity 1s ease-in-out;
}

.carousel-slide.active {
    opacity: 1;
}

.carousel-slide img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.scroll-button {
    position: absolute;
    bottom: 20px;
    right: 20;
    background-color: rgba(0, 0, 0, 0.5);
    color: white;
    border: none;
    padding: 10px 20px;
    cursor: pointer;
    border-radius: 5px;
    font-size: 16px;
    z-index: 999;
}

.scroll-button:hover {
    background-color: rgba(0, 0, 0, 0.7);
}

.persistent-text {
    position: absolute;
    bottom: 10px;
    left: 10px;
    background-color: rgba(0, 0, 0, 0.5);
    padding: 5px 10px;
    border-radius: 5px;
    font-family: 'Open Sans', sans-serif;
    font-size: 2rem;
    text-align: left;
    color: #ffffff;
    margin: 10px 0;
}

.landing-image-size {
    transform: scale(1);
    margin: 10%;
    padding: 1px;
}

.logo {
    width: 30%;
    position: absolute;
    top: 0;
    left: 1%;
    right: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    opacity: 1;
}

.arrow {
    position: absolute;
    width: 5%;
    display: block;
    margin: 10px auto 0 auto;
    bottom: 0;
}