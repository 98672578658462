@import url('https://fonts.googleapis.com/css2?family=Open+Sans:wght@400;700&display=swap');

#contact {
  font-family: 'Open Sans', sans-serif;
  font-size: 1rem;
  color: #fff;
  
}

.contact-acrylic-card {
    background: rgba(76, 147, 253, 0.2);
    backdrop-filter: blur(10px);
    -webkit-backdrop-filter: blur(10px);
    border: 1px solid rgb(76, 147, 253, 0.1);
}

.contact-acrylic-card:hover {
  transform: scale(1.15);
  transition: transform 0.4s ease;
}

/* Asumiendo que la tarjeta de redes sociales tiene la clase 'social-card' */
@media (max-width: 768px) {
  .social-card-social {
    display: none;
  }
}

/* Asumiendo que la tarjeta de redes sociales tiene la clase 'social-card' */
@media (max-width: 430px) {
  .social-card-whatsapp {
    display: none;
  }
}

