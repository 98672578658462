/* VERSION DE ESCRITORIO */
/* VERSION DE ESCRITORIO */
/* VERSION DE ESCRITORIO */

.project-card {
  background: rgba(255, 255, 255, 0.1);
  border: 1px solid rgb(76, 147, 253, 0.1);
  backdrop-filter: blur(10px);
  -webkit-backdrop-filter: blur(10px);
  padding: 10px;
  border-radius: 5px;
  width: 60%;
}

.center-container {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 50vh; /* Asegura que el contenedor ocupe al menos la altura de la ventana */
  padding: 20px; /* Añade un poco de padding para que no esté pegado a los bordes */
}

/* VERSION DE MOVIL */
/* VERSION DE MOVIL */
/* VERSION DE MOVIL */

@media (max-width: 430px) {
  .project-card {
    padding: 10px;
    border-radius: 5px;
    width: 80%;
  }
}

  @keyframes expandImage {
    from {
      transform: scale(0.4);
    }
    to {
      transform: scale(1);
    }
  }