footer {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  padding: 1rem;
  background-color: black;
  color: white;
  text-align: center;
}

.nimajnebs-link {
    color: #ff6347; /* Aquí puedes poner el color que prefieras */
    text-decoration: none; /* Opcional: para quitar el subrayado */
  }
  
  .nimajnebs-link:hover {
    color: #ffa07a; /* Color opcional para el efecto hover */
  }
  