@import url('https://fonts.googleapis.com/css2?family=Open+Sans:wght@400;700&display=swap');

#about {
  font-family: 'Open Sans', sans-serif;
  font-size: 1.25rem;
  text-align: justify;
}

.justify-text {
  text-align: justify;
}

.about-image-size {
  transform: scale(0.75); /* Reduce el tamaño al 90% */
}

@media (max-width: 430px){
  .about-image-size {
    transform: scale(0.8); /* Reduce el tamaño al 50% */
  }

  #about {
    font-size: 1rem;
    text-align: justify;
    margin: 25px;
  }
}