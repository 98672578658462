/* src/components/styles/SocialButton.css */
.social-buttons {
    position: fixed;
    left: 0;
    top: 50%;
    transform: translateY(-50%);
    display: flex;
    flex-direction: column;
    gap: 10px;
  }
  
  .social-button {
    background-color: #000000;
    padding: 10px;
    border-radius: 0 10px 10px 0;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 50px;
    height: 50px;
  }
  
  .social-button img {
    width: 24px; /* Ajusta el tamaño según sea necesario */
    height: 24px; /* Ajusta el tamaño según sea necesario */
    z-index: 1000;
  }