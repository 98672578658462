/* VERSION DE ESCRITORIO */
/* VERSION DE ESCRITORIO */
/* VERSION DE ESCRITORIO */

.service-card {
  flex-direction: row;
  align-items: center;
  background: rgba(255, 255, 255, 0.1);
  border: 1px solid rgb(76, 147, 253, 0.1);
  backdrop-filter: blur(10px);
  -webkit-backdrop-filter: blur(10px);
  border-radius: 5px;
  width: 60%;
  margin-top: 0;
  margin-bottom: 0;
  padding: 1%;
}

.service-subcard {
  background: rgba(46, 37, 37, 0.308);
  border: 1px solid rgb(76, 147, 253, 0.1);
  backdrop-filter: blur(10px);
  -webkit-backdrop-filter: blur(10px);
  border-radius: 5px;
  width: 55%;
  padding: 3%;
}

.service-card .service-title {
  margin-top: 0px;
  margin-bottom: 0px;
  align-items: center;
  font-family: 'Open Sans', sans-serif;
  font-size: 1.5rem;
  font-weight: bolder;
  text-align: left;
  color: #ffffff;
  margin: 10px 0;
  padding: 5px;
}

.service-card .more-details-button {
  width: 200px;
  background: rgba(255, 255, 255, 0.1);
  border: 1px solid rgb(76, 147, 253, 0.1);
  backdrop-filter: blur(10px);
  -webkit-backdrop-filter: blur(10px);
  border-radius: 5px;
  color: #ffffff;
  font-family: 'Open Sans', sans-serif;
  font-size: 1rem;
  margin: 20px;
  padding: 5px;
}

.service-card .service-description {
  font-family: 'Open Sans', sans-serif;
  font-size: 1.1rem;
  text-align: justify;
  margin-top: 3%;
  margin-right: 10%;
  padding: 5px;
}

.service-subcard .service-subtitle {
  font-family: 'Open Sans', sans-serif;
  font-size: 1.5rem;
  font-weight: bolder;
  text-align: left;
  color: #ffffff;
  margin-top: 10px;
  margin-bottom: 0px;
  margin-left: 60px;
  margin-right: 10%;
  padding-top: 85px;
}

.service-subcard .service-subdescription {
  font-family: 'Open Sans', sans-serif;
  font-size: 1rem;
  text-align: justify;
  margin-top: 20px;
  margin-bottom: 0px;
  margin-left: 60px;
  margin-right: 60px;
}

.service-subcard .services-subimage-size {
  transform: scale(0.5);
  margin: 0px; 
  padding: 0px 0px 0px 0px;
  min-width: 250px;
  min-height: 200px;
  max-width: 250px;
  max-height: 250px;
}

.service-card .service-image-size {
  transform: scale(0.8);
  margin: 1px; 
  padding: 30px;
  min-width: 380px;
  min-height: 380px;
  max-width: 380px;
  max-height: 380px;
}

.iframe-container {
  display: flex;
  justify-content: center;
  width: 100%;
  height: 100%;
  padding: 0px;
}

.media-renderer {
  max-width: 100%;
  min-width: 380px;
  min-height: 380px;
  max-width: 380px;
  max-height: 380px;
}

@media (max-width: 430px){
  .service-card .service-description {
    display: none;
  }

  .service-card {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    border-radius: 5px;
    width: 70%;
  }

  .service-subcard {
    border-radius: 5px;
    width: 70%;
    padding: 15px;
    margin: 0;
  }

  .service-card .service-title {
    margin-top: 0px;
    margin-bottom: 0px;
    align-items: left;
    font-family: 'Open Sans', sans-serif;
    font-size: 1.5rem;
    font-weight: bolder;
    text-align: center;
    margin: 0;
    padding: 0px;
  }

  .service-card .service-image-size {
    display: flex;
    transform: scale(0.55);
    margin: 0px;
    padding: 0px;
    }

  .service-card .more-details-button {
    width: 150px;
    margin: 15px;
  }

  .service-subcard .services-subimage-size {
    display: none;
  }

  .service-subcard .service-subtitle {
    font-family: 'Open Sans', sans-serif;
    font-size: 1.5rem;
    font-weight: bolder;
    text-align: left;
    color: #ffffff;
    margin-top: 20px;
    margin-bottom: 20px;
    margin-left: 0;
    margin-right: 0;
    padding-top: 0px;
  }

  .service-subcard .service-subdescription {
    text-align: justify;
    margin-left: 1%;
    margin-right: 1%;
    margin-bottom: 1%;
    margin-top: 1%;
  }
}

.service-expand-enter {
  opacity: 0;
  transform: scale(0.9);
}

.service-expand-enter-active {
  opacity: 1;
  transform: scale(1);
  transition: opacity 700ms ease-in-out, transform 700ms ease-in-out;
}

.service-expand-exit {
  opacity: 1;
  transform: scale(1);
}

.service-expand-exit-active {
  opacity: 0;
  transform: scale(0.9);
  transition: opacity 700ms ease-in-out, transform 700ms ease-in-out;
}